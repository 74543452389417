ion-grid.custom-row-table-box-grid {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  padding-left: 1%;
  padding-right: 1%;
}

ion-grid ion-row.mpp-grid-title-row {
  background-color: var(--morrisons-color-title) !important;
  color: var(--morrisons-color-title-text) !important;
  text-align: center;
  font-family: Agenda-Semibold !important;
}

ion-grid ion-row.mpp-grid-header-row {
  background-color: var(--morrisons-color-box-title) !important;
  color: var(--morrisons-color-row-text) !important;
  border-bottom: 1px solid var(--morrisons-color-tertiary-dark) !important;
  text-align: center;
  font-family: Agenda-Semibold !important;
  font-size: medium;
}

ion-grid ion-row.mpp-grid-value-row {
  border-bottom: 1px solid var(--morrisons-color-box-line) !important;
  text-align: left;
}

ion-grid ion-row.mpp-grid-value-row:nth-of-type(2n + 1) {
  font-family: Agenda-Light !important;
  font-size: medium;
  color: var(--morrisons-color-box-value-text) !important;
  background-color: var(--morrisons-color-alternate-nth) !important;
}

ion-grid ion-row.mpp-grid-value-row:nth-of-type(2n) {
  font-family: Agenda-Light !important;
  font-size: medium;
  color: var(--morrisons-color-box-value-text) !important;
  background-color: var(--morrisons-color-alternate-2nth) !important;
}

ion-grid
  ion-row.mpp-grid-value-row
  ion-grid
  ion-row
  ion-col.mpp-grid-value-col {
  font-family: Agenda-Light !important;
  color: var(--morrisons-color-box-value-text) !important;
  text-align: left;
}

ion-grid ion-row ion-col.mpp-grid-value-col-center {
  font-family: Agenda-Light !important;
  color: var(--morrisons-color-box-value-text) !important;
  text-align: center;
}

ion-grid ion-row ion-col.mpp-grid-value-col-num {
  font-family: Agenda-Light !important;
  color: var(--morrisons-color-box-value-text) !important;
  text-align: right;
}

ion-grid ion-row.mpp-grid-value-row:nth-child() {
  font-family: Agenda-Light !important;
  background-color: var(--morrisons-color-tertiary-lighter) !important;
  color: var(--morrisons-color-box-value-text) !important;
  border: 1px solid var(--morrisons-color-primary-dark) !important;
  text-align: left;
}

ion-grid ion-row:nth-child() {
  border: 1px solid var(--morrisons-color-alternate-row) !important;
}

.mpp-grid-value-row-highlighted {
  background-color: var(--morrisons-color-highlighted) !important;
}
