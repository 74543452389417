.mpp_search_bar {
  width: 95%;
  min-width: 324px;
  --background: var(--morrisons-color-secondary-lighter) !important;
  font-family: Agenda-Medium !important;
  font-size: medium;
  color: var(--morrisons-color-filter-key-text) !important;
  text-align: left;
}

.mpp_custom_grid {
  max-height: 150px;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
}

.mpp_custom_grid_row {
  width: 99%;
  margin-top: 1px;
  margin-bottom: 1px;
  background: var(--morrisons-color-tertiary-lightest) !important;
}

.mpp_custom_grid_lebel {
  text-align: left;
  pointer-events: all !important;
  font-family: Agenda-Medium !important;
  font-size: medium;
  color: var(--morrisons-color-filter-key-text) !important;
  text-align: left;
}
