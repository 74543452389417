ion-menu ion-content ion-list ion-menu-toggle ion-item ion-label {
  margin-left: 10px !important;
}

ion-menu ion-header ion-toolbar {
  --background: var(--morrisons-color-secondary-lightest) !important;
}

ion-menu ion-header ion-toolbar ion-col {
  align-content: center;
}

ion-menu ion-header ion-toolbar ion-grid ion-row ion-col {
  align-self: center;
}

ion-menu ion-header ion-toolbar ion-grid ion-row ion-col ion-text {
  color: var(--morrisons-color-box-key-text) !important;
  font-family: Agenda-Semibold !important;
  font-size: large;
  padding-left: 10px;
}

ion-menu ion-content {
  --ion-background-color: var(--morrisons-color-secondary-lightest) !important;
}

ion-menu ion-content ion-list {
  background-color: var(--morrisons-color-secondary-lightest) !important;
}

.mpp-menu-icon {
  color: var(--morrisons-color-box-key-text) !important;
}

.mpp-menu-button {
  font-family: Agenda-Medium !important;
  color: var(--morrisons-color-box-key-text) !important;
}
