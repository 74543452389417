.mpp-ion-grid {
  align-content: space-between;
  padding-left: 0%;
  padding-right: 0%;
  margin-left: 0%;
  margin-right: 0%;
}

.mpp-ion-grid-infobox {
  min-width: 360px;
  max-width: 400px;
  flex-wrap: wrap;
  align-content: stretch;
}

.fixedLabelWeek {
  font-family: Agenda-Light !important;
  min-width: 15% !important;
  max-width: 15% !important;
  font-size: 12px;
  text-align: right;
  padding-right: 5px;
}

.fixedLabelDay {
  font-family: Agenda-Light !important;
  min-width: 15% !important;
  max-width: 15% !important;
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}

.fixedLabelTurnover {
  font-family: Agenda-Light !important;
  min-width: 20% !important;
  max-width: 20% !important;
  font-size: 12px;
  text-align: right;
  padding-right: 5px;
}

.fixedLabelCostOfSales {
  font-family: Agenda-Light !important;
  min-width: 21% !important;
  max-width: 21% !important;
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}

.mppToggle {
  width: 50px;
  height: 15px;
  --background-checked: var(--morrisons-color-toggle-left-selected) !important;
  --background: var(--morrisons-color-toggle-left-selected) !important;
  --handle-background: white !important;
  --handle-background-checked: white !important;
}

.mppEnableDisableToggle {
  width: 50px;
  height: 15px;
  --background-checked: var(--morrisons-color-toggle-right-selected) !important;
  --background: gray !important;
  --handle-background: white !important;
  --handle-background-checked: white !important;
}

.mpp-product-name {
  text-align: center;
  font-size: medium;
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-procuct-name) !important;
  border-bottom: 1px solid var(--morrisons-color-box-line) !important;
}

.mpp-segment-lebel {
  font-family: Agenda-Medium !important;
  color: var(--morrisons-color-button) !important;
  text-transform: none;
}

.mpp-segment-lebel-selected {
  font-family: Agenda-Medium !important;
  color: var(--morrisons-color-button-text) !important;
  text-transform: none;
}

ion-segment {
  --background: var(--morrisons-color-highlighted) !important;
}

ion-segment-button::part(indicator-background) {
  background: var(--morrisons-color-button) !important;
}

/* Material Design styles */
ion-segment-button.md::part(native) {
  color: var(--morrisons-color-button) !important;
  border: 1px solid var(--morrisons-color-button) !important;
}

.segment-button-checked.md::part(native) {
  color: var(--morrisons-color-button) !important;
}

ion-segment-button.md::part(indicator-background) {
  height: 100px;
}

/* iOS styles */
ion-segment-button.ios::part(native) {
  color: var(--morrisons-color-button) !important;
}

.segment-button-checked.ios::part(native) {
  color: var(--morrisons-color-button) !important;
}

ion-segment-button.ios::part(indicator-background) {
  border-radius: 10px;
}
