.custom-table-box-grid {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 98%;
}

.custom-table-box-grid-title-row {
  background-color: var(--morrisons-color-primary-dark) !important;
  color: var(--morrisons-color-tertiary) !important;
  border: 1px solid var(--morrisons-color-primary-dark) !important;
  text-align: center;
}

.custom-table-box-grid-column-row {
  color: var(--morrisons-color-row-text) !important;
  border-bottom: 1px solid var(--morrisons-color-box-line) !important;
  text-align: center;
  max-width: 400px;
}

.custom-table-box-grid-column-row-total {
  color: var(--morrisons-color-row-text) !important;
  border-top: 1px solid var(--morrisons-color-tertiary-dark) !important;
  border-bottom: 1px solid var(--morrisons-color-box-line) !important;
  text-align: center;
  max-width: 400px;
}

.mpp-min-link {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-product-link) !important;
  font-size: large;
  cursor: pointer;
}

.mpp-min-link.ios {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-product-link-hover) !important;
  font-size: large;
}

.mpp-min-link:hover {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-product-link-hover) !important;
  font-size: large;
  text-decoration: underline;
}

.custom-table-box-grid-column-row:nth-of-type(2n + 1) {
  background-color: var(--morrisons-color-box-title) !important;
}

.custom-table-box-grid-column-row:nth-of-type(2n) {
  background-color: var(--morrisons-color-alternate-nth) !important;
}

.custom-table-box-grid-column-row:last-of-type {
  margin-bottom: 10px;
}

.custom-table-box-grid-column-header:nth-of-type(2n + 1) {
  font-family: Agenda-Light !important;
  color: var(--morrisons-color-box-key-text) !important;
  text-align: left;
  font-size: medium;
}

.custom-table-box-grid-column-header:nth-of-type(2n) {
  font-family: Agenda-Light !important;
  color: var(--morrisons-color-box-key-text) !important;
  text-align: left;
  font-size: medium;
}

.custom-table-box-grid-column-header-total {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-box-key-text) !important;
  text-align: left;
  font-size: medium;
}

.custom-table-with-total-box-grid-column-value {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-box-value-text) !important;
  text-align: right;
  font-size: large;
}
