ion-menu ion-content ion-list ion-menu-toggle ion-item ion-label {
  margin-left: 10px !important;
}

ion-menu ion-header ion-toolbar {
  --background: white !important;
}

ion-menu ion-header ion-toolbar ion-col {
  align-content: center;
}

ion-menu ion-header ion-toolbar ion-grid ion-row ion-col {
  align-self: center;
}

ion-menu ion-header ion-toolbar ion-grid ion-row ion-col ion-text {
  color: var(--morrisons-color-primary-dark) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: bold;
  padding-left: 10px;
}

ion-menu ion-content {
  --ion-background-color: var(--morrisons-color-secondary-lightest) !important;
}

ion-menu ion-content ion-list {
  background-color: var(--morrisons-color-secondary-lightest) !important;
}

ion-menu ion-content ion-list ion-menu-toggle ion-item {
  color: red !important;
}

ion-menu ion-content ion-list ion-menu-toggle ion-item ion-icon {
  color: var(--morrisons-color-primary-dark) !important;
}

ion-menu ion-content ion-list ion-menu-toggle ion-item ion-label {
  color: var(--morrisons-color-primary-dark) !important;
}

.mpp-menu-icon {
  color: var(--morrisons-color-box-key-text) !important;
}

.mpp-menu-button {
  font-family: Agenda-Medium !important;
  color: var(--morrisons-color-box-key-text) !important;
}
