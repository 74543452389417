.menu-button {
  text-transform: capitalize;
  padding-left: 15%;
  font-size: medium;
  width: 85%;
  align-self: center;
  font-weight: bold;
  --background: var(--morrisons-color-primary) !important;
  --color: white !important;
}

.mpp-header-label {
  font-size: medium;
  color: var(--morrisons-color-primary-dark) !important;
  text-align: left;
}

.mpp-ion-grid {
  align-content: space-between;
}

.mpp-ion-grid-row {
  align-content: stretch;
  justify-content: flex-start;
}

.mpp-ion-grid-infobox {
  min-width: 360px;
}

.mpp-ion-grid-infobox-header {
  --background: var(--morrisons-color-secondary) !important;
  height: 40px;
  font-weight: bold;
  border-radius: 10px;
}

.mpp-ion-grid-infobox-image {
  position: relative;
  align-self: end;
  height: 250px;
  width: 250px;
  margin: 0 auto;
}

.mpp-ion-grid-infobox-header-title {
  font-size: medium;
  color: var(--morrisons-color-tertiary-light) !important;
  padding-bottom: 15px;
}

.mpp-ion-grid-infobox-content-row {
  position: relative;
  margin: 0 auto;
  align-self: center;
  align-content: center;
  justify-self: center;
  justify-content: center;
  flex-direction: row;
}

.first-row-first-column {
  border-top-left-radius: 5px;
}

.first-row-last-column {
  border-top-right-radius: 5px;
}

.last-row-first-column {
  border-bottom-left-radius: 5px;
}

.last-row-last-column {
  border-bottom-right-radius: 5px;
}

.mpp-ion-grid-infobox-content-col-odd {
  background-color: var(--morrisons-color-secondary-lighter) !important;
  justify-content: center;
}

.mpp-ion-grid-infobox-content-col-even {
  background-color: var(--morrisons-color-tertiary-lighter) !important;
  justify-content: center;
}

.mpp-ion-grid-infobox-content-key {
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
}

.mpp-ion-grid-infobox-content-value {
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
}
