.morrisons-login-grid {
  justify-self: center;
}

.morrisons-login-grid-mobile {
  padding-top: 45%;
  justify-self: center;
  overflow: hidden;
}

.morrisons-image-col {
  padding-left: 15%;
  width: 85%;
  align-self: center;
}

.morrisons-image {
  padding-left: 30%;
  width: 70%;
  align-self: center;
}

.login-button {
  padding-left: 15%;
  width: 85%;
  align-self: center;
  font-weight: bold;
  --background: var(--morrisons-color-primary) !important;
  --color: white !important;
  text-transform: capitalize;
  font-weight: bold;
}

.application-name {
  width: 85%;
  color: var(--morrisons-color-title) !important;
  text-align: center;
  font-size: large;
  font-family: Agenda-Semibold !important;
}
