.mpp_modal_title {
  font-family: Agenda-Semibold !important;
  color: var(--morrisons-color-filter-key-text) !important;
  font-size: large;
  text-align: left;
}

.mpp_modal_sub_title {
  font-family: Agenda-Medium !important;
  color: var(--morrisons-color-filter-key-text) !important;
  font-size: small;
  text-align: left;
}
