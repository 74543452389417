/** Morrisons Fonts **/
@font-face {
  font-family: "Agenda-Light";
  font-style: normal;
  font-weight: lighter;
  src: url("../assets/fonts/MorrisonsAgenda-Light.ttf");
}

@font-face {
  font-family: "Agenda-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/MorrisonsAgenda-Medium.ttf");
}

@font-face {
  font-family: "Agenda-Semibold";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/MorrisonsAgenda-Semibold.ttf");
}

@font-face {
  font-family: "Agenda-Bold";
  font-style: normal;
  font-weight: bolder;
  src: url("../assets/fonts/MorrisonsAgenda-Bold.ttf");
}

@font-face {
  font-family: "MajaWeb-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/MorrisonsMajaWeb-Bold.ttf");
}

@font-face {
  font-family: "MajaWeb-BoldItalic";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/MorrisonsMajaWeb-BoldItalic.ttf");
}
