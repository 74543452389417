.scanner {
  display: none;
}
.scanner.visible {
  display: block;
}

#scanner-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  z-index: -2;
}

#scanner-video video {
  object-fit: initial;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 99%;
  width: 99%;
  z-index: -1;
  @media (orientation: portrait) {
    width: 100%;
    height: auto;
  }
}

#scanner-frame {
  position: absolute;
  margin: auto;
  box-shadow: 0 0 0 9999px rgba(9, 9, 9, 0.8);
  border: 2px solid rgb(81, 255, 0);
  z-index: 9999;
}

#scanner-canvas {
  display: none;
  margin: auto;
}

#scanner-image-hidden {
  display: none;
  position: absolute;
  margin: auto;
}

#scanner-image {
  display: none;
  position: absolute;
  margin: auto;
}
