.mpp-template-header-toolbar {
  --background: var(--morrisons-color-banner) !important;
  justify-content: start;
}

.mpp-template-header-toolbar.ios {
  --background: var(--morrisons-color-banner) !important;
}

.mpp-template-header-button-text,
.mpp-template-header-button-text.ios {
  color: white !important;
  font-family: Agenda-Medium !important;
  font-size: larger;
  font-weight: bold;
}

.mpp-template-header-button-icon {
  color: white;
}

.mpp-template-header-logo {
  width: 100px;
  height: 50px;
  margin-right: 3px;
  padding-right: 1px;
}

.mpp-template-header-logo.ios {
  width: 12%;
  height: 8%;
  margin-right: 1px;
  margin-right: 1px;
}
